export const DEFAULT_FONT_SIZE = '15px';
export const DEFAULT_FONT_COLOR = '#000';
export const DEFAULT_FONT_BG_COLOR = '#ffffff';
export const DEFAULT_FONT_FAMILY = 'Arial';

export const DEFAULT_FONT_SIZES = [8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72];

export const DEFAULT_FONT_FAMILIES = [
  { font: 'Arial', fallbacks: 'Helvetica, sans-serif' },
  { font: '"Brush Script MT"', fallbacks: 'cursive' },
  { font: 'Copperplate', fallbacks: 'Papyrus, fantasy' },
  { font: '"Courier New"', fallbacks: 'Courier, monospace' },
  { font: 'Garamond', fallbacks: 'serif' },
  { font: 'Geneva', fallbacks: 'Verdana, sans-serif' },
  { font: 'Georgia', fallbacks: 'serif' },
  { font: 'Tahoma', fallbacks: 'Verdana, sans-serif' },
  { font: '"Times New Roman"', fallbacks: 'Times, serif' },
  { font: '"Trebuchet MS"', fallbacks: 'Helvetica, sans-serif' },
  { font: 'Verdana', fallbacks: 'Times, serif' },
];
