import { $isLinkNode, LinkNode } from '@lexical/link';
import { $isAtNodeEnd } from '@lexical/selection';
import { RangeSelection, LexicalNode, PointType, NodeKey } from 'lexical';

export function getSelectedNode(selection: RangeSelection): LexicalNode {
  const { anchor } = selection;
  const { focus } = selection;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  }
  return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
}

/**
 * Checks whether the anchor or focus offset is at the nodeEnd or nodeStart of the
 * provided node or its parent node.
 *
 * @param anchor Anchor point of a selection
 * @param focus Focus point of a selection
 * @param nodeKey Key for the selected node
 * @param parentKey Key for the selected node's parent node
 * @param isBackwards True if selection is backwards (focus before anchor)
 * @returns True if either end of the selection is at the boundary of the node
 */
function isCursorAtNodeBoundary(
  anchor: PointType,
  focus: PointType,
  nodeKey: NodeKey,
  parentKey: NodeKey,
  isBackwards: boolean
): boolean {
  const focusKey = focus.getNode().getKey();
  const anchorKey = anchor.getNode().getKey();

  const focusKeyEqualsNodeKey = nodeKey === focusKey || parentKey === focusKey;
  const anchorKeyEqualsNodeKey = nodeKey === anchorKey || parentKey === anchorKey;

  if (isBackwards) {
    // focus first
    if (focusKeyEqualsNodeKey && $isAtNodeEnd(focus)) {
      return true;
    }
    if (anchorKeyEqualsNodeKey && anchor.offset === 0) {
      return true;
    }
  } else {
    // anchor first
    if (anchorKeyEqualsNodeKey && $isAtNodeEnd(anchor)) {
      return true;
    }
    if (focusKeyEqualsNodeKey && focus.offset === 0) {
      return true;
    }
  }

  return false;
}

/**
 * Attempts to find the link node in a selection. If more than one is found,
 * or if no link nodes are found, returns null.
 *
 * @param selection Selection in the Lexical editor
 * @returns A link node if only one found, null otherwise
 */
export function findLinkNode(selection: RangeSelection): LinkNode | null {
  const nodesInSelection = selection.getNodes();
  const linkNodesInSelection = new Set<LinkNode>();

  const { anchor, focus } = selection;

  nodesInSelection.forEach((node) => {
    const parentNode = node.getParent();
    if (
      $isLinkNode(node) &&
      !isCursorAtNodeBoundary(
        anchor,
        focus,
        node.getChildAtIndex(0)?.getKey() || 'NaN',
        node.getKey(),
        selection.isBackward()
      )
    ) {
      linkNodesInSelection.add(node);
    } else if (
      $isLinkNode(parentNode) &&
      !isCursorAtNodeBoundary(anchor, focus, node.getKey(), parentNode.getKey(), selection.isBackward())
    ) {
      linkNodesInSelection.add(parentNode);
    }
  });

  if (linkNodesInSelection.size === 1) {
    return linkNodesInSelection.values().next().value!;
  }

  return null;
}
