import React, { FC, useEffect, useState } from 'react';
import { ICustomerSupportValueState } from 'context/customerSupportValue';
import { Button, Switch } from 'antd';
import { CloseOutlined, EditOutlined, SaveOutlined } from 'components/icons';
import type { SerializedEditorState } from 'lexical';
import ToggleableTextArea from './toggleableTextArea';
import ToggleableTextInput from './toggleableTextInput';
import RichTextEditor from '../RichTextEditor';

interface ICusotmerSupportDetails {
  customerSupportValueState: ICustomerSupportValueState;
  submitFailed: boolean;
  onSave: (csv: ICustomerSupportValueState) => void;
}

// NOTE: As currently implemented, there is no support for collaborative edits. If two people are editing at the same time,
//       the last one to save wins. That said, one change we can make to improve the behavior is to fetch the current state
//       of all support values when the user clicks the "Edit" button. This will at least ensure they are editing the most
//       recent version. The flip side is that the content of the page could change between what they see before and after
//       the click "Edit."

const CustomerSupportDetails: FC<ICusotmerSupportDetails> = ({ customerSupportValueState, submitFailed, onSave }) => {
  const [editState, setEditState] = useState<boolean>(false);

  const [displayValues, setDisplayValues] = useState<ICustomerSupportValueState>(customerSupportValueState);

  useEffect(() => {
    setDisplayValues(customerSupportValueState);
  }, [customerSupportValueState]);

  useEffect(() => {
    // If the `onSave` call failed, stay in editing mode so that the user knows they need to try again.
    setEditState(submitFailed);
  }, [submitFailed]);

  return (
    <div className="CustomerSupportDetails">
      <div className="CustomerSupportDetails__Container">
        <div className="CustomerSupportDetails__Column">
          <h2>Support Guidelines</h2>
          <div className="CustomerSupportDetails__Row">
            <span>Patient Support Allowed?</span>
            <Switch
              className="CustomerSupportDetails__Switch"
              data-testid="customer-support-values_patientSupportAllowed"
              title="If enabled, the customer support agent is allowed to assist patients directly."
              checked={displayValues.patientSupportAllowed}
              disabled={!editState}
              onChange={(checked: boolean) => {
                setDisplayValues((csv) => ({ ...csv, patientSupportAllowed: checked }));
              }}
            />
          </div>
          <div className="CustomerSupportDetails__Row">
            <span>Patient Support Redirect:</span>
            <ToggleableTextArea
              editModeActive={editState}
              placeholderText="Enter Patient Support Redirect"
              textAreaRows={3}
              fieldName="patientSupportRedirect"
              currentState={displayValues}
              testidPrefix="customer-support-values"
              onChangeFn={setDisplayValues}
            />
          </div>
          <div className="CustomerSupportDetails__Row">
            <span>Discuss Test Results?</span>
            <Switch
              className="CustomerSupportDetails__Switch"
              data-testid="customer-support-values_discussTestResults"
              title="If enabled, the customer support agent is allowed to discuss test results."
              checked={displayValues.discussTestResults}
              disabled={!editState}
              onChange={(checked: boolean) => {
                setDisplayValues((csv) => ({ ...csv, discussTestResults: checked }));
              }}
            />
          </div>
          <div className="CustomerSupportDetails__Row">
            <span>Logistics Support?</span>
            <Switch
              className="CustomerSupportDetails__Switch"
              data-testid="customer-support-values_logisticsSupport"
              title="If enabled, you are able to assist participants with shipments and other logistics related problems. If disabled, redirect to the study coordinator or customer."
              checked={displayValues.logisticsSupport}
              disabled={!editState}
              onChange={(checked: boolean) => {
                setDisplayValues((csv) => ({ ...csv, logisticsSupport: checked }));
              }}
            />
          </div>
          <div className="CustomerSupportDetails__Row">
            <span>Device Troubleshooting?</span>
            <Switch
              className="CustomerSupportDetails__Switch"
              data-testid="customer-support-values_deviceTroubleshooting"
              title="If enabled, the customer support agent is allowed to attempt to troubleshoot issues with the device."
              checked={displayValues.deviceTroubleshooting}
              disabled={!editState}
              onChange={(checked: boolean) => {
                setDisplayValues((csv) => ({ ...csv, deviceTroubleshooting: checked }));
              }}
            />
          </div>
          <div className="CustomerSupportDetails__Row">
            <span>Issue Replacements?</span>
            <Switch
              className="CustomerSupportDetails__Switch"
              data-testid="customer-support-values_issueReplacements"
              title="If enabled, the customer support agent is allowed to order a replacement kit."
              checked={displayValues.issueReplacements}
              disabled={!editState}
              onChange={(checked: boolean) => {
                setDisplayValues((csv) => ({ ...csv, issueReplacements: checked }));
              }}
            />
          </div>
        </div>
        <div className="CustomerSupportDetails__Column">
          <h2>Support Contact Information</h2>
          <div className="CustomerSupportDetails__Row">
            <span>Name</span>
            <ToggleableTextInput
              editModeActive={editState}
              placeholderText="Enter Support Contact Name"
              fieldName="contactName"
              currentState={displayValues}
              testidPrefix="customer-support-values"
              onChangeFn={setDisplayValues}
            />
          </div>
          <div className="CustomerSupportDetails__Row">
            <span>Phone Number</span>
            <ToggleableTextInput
              editModeActive={editState}
              placeholderText="Enter Support Contact Phone Number"
              fieldName="contactPhone"
              currentState={displayValues}
              testidPrefix="customer-support-values"
              onChangeFn={setDisplayValues}
            />
          </div>
          <div className="CustomerSupportDetails__Row">
            <span>Email</span>
            <ToggleableTextInput
              editModeActive={editState}
              placeholderText="Enter Support Contact Email"
              fieldName="contactEmail"
              currentState={displayValues}
              testidPrefix="customer-support-values"
              onChangeFn={setDisplayValues}
            />
          </div>
        </div>
      </div>
      <div className="CustomerSupportDetails__Container" style={{ justifyContent: 'flex-start' }}>
        <div className="CustomerSupportDetails__Column" style={{ width: '100%' }}>
          <h2 style={{ marginBottom: '0px' }}>Notes</h2>
          <div className="CustomerSupportDetails__Row" style={{ marginTop: '0px' }}>
            <RichTextEditor
              editModeActive={editState}
              placeholderText="Enter customer support notes here"
              editorState={customerSupportValueState.supportNotes}
              testidPrefix="customer-support-values"
              onChangeFn={(editorState: SerializedEditorState) => {
                setDisplayValues((csv) => ({ ...csv, supportNotes: editorState }));
              }}
            />
          </div>
        </div>
      </div>
      <div className="CustomerSupportDetails__Container">
        {!editState ? (
          <Button
            data-testid="customer-support-values_edit"
            type="primary"
            icon={<EditOutlined />}
            onClick={() => setEditState(true)}
          >
            Edit Page
          </Button>
        ) : (
          <>
            <Button
              data-testid="customer-support-values_save"
              type="primary"
              icon={<SaveOutlined />}
              onClick={() => {
                setEditState(false);
                onSave(displayValues);
              }}
            >
              Save
            </Button>
            <Button
              data-testid="customer-support-values_cancel"
              type="default"
              icon={<CloseOutlined />}
              onClick={() => {
                setEditState(false);
                setDisplayValues(customerSupportValueState);
              }}
              style={{ marginLeft: '5px' }}
            >
              Cancel
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default CustomerSupportDetails;
