import React, { useCallback, useContext, useEffect } from 'react';
import { Popover, Button } from 'antd';
import Compact from '@uiw/react-color-compact';

import { useEditor } from 'components/RichTextEditor/EditorContext';

import { BgColorsOutlined } from '@ant-design/icons';
import ToolbarContext from '../ToolbarContext';
import { DEFAULT_FONT_BG_COLOR } from './DefaultValues';

const BackgroundColorPicker = () => {
  const { editor } = useEditor();
  const {
    isToolbarActive,
    bgColorHex,
    applyStyleText,
    fontColorSelectOpen,
    setFontColorSelectOpen,
    fontBackgroundSelectOpen,
    setFontBackgroundSelectOpen,
  } = useContext(ToolbarContext);

  const onBackgroundColorSelect = useCallback(
    (value: string) => {
      applyStyleText({ 'background-color': value });
    },
    [applyStyleText]
  );

  useEffect(() => {
    if (!editor.isEditable()) {
      setFontBackgroundSelectOpen(false);
    }
  }, [editor.isEditable()]);

  return (
    <Button
      data-testid="richTextEditor_toolbar_backgroundColorButton"
      className="toolbar-item spaced"
      aria-label="Text Background Color"
      disabled={!isToolbarActive}
      onClick={() => {
        if (editor.isEditable()) {
          if (fontColorSelectOpen) {
            setFontColorSelectOpen(false);
          }
          setFontBackgroundSelectOpen(!fontBackgroundSelectOpen);
        }
      }}
    >
      <Popover
        data-testid="richTextEditor_toolbar_backgroundColorPopover"
        placement="bottom"
        title="Background Color"
        trigger="click"
        open={fontBackgroundSelectOpen}
        onOpenChange={() => {
          if (fontBackgroundSelectOpen) {
            // Only toggle it if the Popover is already open, otherwise we get a race condition
            // with the outer Button.
            setFontBackgroundSelectOpen(false);
          }
        }}
        content={
          <div>
            <Button
              data-testid="richTextEditor_toolbar_backgroundColorPopover_resetToDefaultButton"
              className="color-selector reset-button"
              aria-label="Clear Background Color"
              block
              onClick={() => {
                onBackgroundColorSelect(DEFAULT_FONT_BG_COLOR);
                setFontBackgroundSelectOpen(false);
              }}
            >
              No Color
            </Button>
            <Compact
              data-testid="richTextEditor_toolbar_backgroundColorPopover_colorChart"
              color={bgColorHex}
              style={{ background: `rgba(255, 255, 255, 0.85)` }}
              onChange={(color) => {
                onBackgroundColorSelect(color.hex);
                setFontBackgroundSelectOpen(false);
              }}
            />
          </div>
        }
      >
        <BgColorsOutlined />
      </Popover>
    </Button>
  );
};

export default BackgroundColorPicker;
