import React, { FC, useEffect, useState } from 'react';

import { Form, Select, Modal } from 'antd';
import { IProject } from 'context/projects';

const { Option } = Select;

interface IPublicApiCredentialFormModal {
  projects: IProject[];
  visible: boolean;
  loading: boolean;
  existingSelectedProjectIds?: string[];
  onOk: (projectIds: string[]) => void;
  onCancel: () => void;
}

const PublicApiCredentialFormModal: FC<IPublicApiCredentialFormModal> = ({
  projects,
  visible,
  loading,
  existingSelectedProjectIds,
  onOk,
  onCancel,
}) => {
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);

  useEffect(() => {
    setSelectedProjects(existingSelectedProjectIds ?? []);
  }, [visible]);

  // Handle project multi-select
  const handleProjectSelect = (value: string[]) => {
    setSelectedProjects(value);
  };

  return (
    <Modal
      wrapClassName="Modal"
      visible={visible}
      title={existingSelectedProjectIds ? 'Update Credential' : 'Create Credential'}
      okText={existingSelectedProjectIds ? 'Update' : 'Create'}
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => onOk(selectedProjects)}
      confirmLoading={loading}
      centered
      width="720px"
      forceRender
      okButtonProps={{ disabled: selectedProjects.length === 0 }}
    >
      <Form layout="vertical" style={{ width: '80%' }}>
        <Form.Item label="Select Project(s)">
          <Select
            mode="multiple"
            value={selectedProjects}
            onChange={handleProjectSelect}
            placeholder="Select Project(s)"
            style={{ width: '100%' }}
          >
            {projects.map((project) => (
              <Option key={project.id} value={project.id}>
                {project.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PublicApiCredentialFormModal;
