import React, { FC, useEffect, useRef } from 'react';

import { SerializedEditorState, CLEAR_EDITOR_COMMAND, EditorState, LexicalEditor } from 'lexical';

import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { AutoLinkPlugin } from '@lexical/react/LexicalAutoLinkPlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { EditorRefPlugin } from '@lexical/react/LexicalEditorRefPlugin';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';

import ToolbarPlugin from './Plugins/ToolbarPlugin';
import TextEditorNodes from './LexicalNodes';
import { EditorProvider } from './EditorContext';
import EditorTheme from './EditorTheme';
import MATCHERS from './Plugins/AutoLinkPluginMatchers';

interface IToggleableRichTextEditor {
  editModeActive: boolean;
  placeholderText: string;
  editorState: SerializedEditorState | null | undefined;
  testidPrefix: string;
  onChangeFn: (updateState: SerializedEditorState) => void;
}

/**
 * The RichTextEditor component expects the containing feature to maintain two types of state:
 * the active / live state (what is seen by the user while editModeActive = true), and the
 * saved state (the source of truth). `onChangeFn` should update the ACTIVE state while
 * `editorState` represents the current source of truth.
 */
const RichTextEditor: FC<IToggleableRichTextEditor> = ({
  editModeActive,
  placeholderText,
  editorState,
  testidPrefix,
  onChangeFn,
}) => {
  const editorRef = useRef<LexicalEditor>(null);

  function onChange(state: EditorState) {
    const editorStateJSON = state.toJSON();
    onChangeFn(editorStateJSON);
  }

  const editorConfig = {
    namespace: testidPrefix,
    nodes: [...TextEditorNodes],
    editable: editModeActive,
    // Handling of errors during update
    onError(error: Error) {
      console.error(error);
    },
    theme: EditorTheme, // Note: Could make this a parameter if we want this to be customizable.
  };

  // If editModeActive has toggled from true to false (i.e., someone has clicked Save or Cancel),
  // force the editor state back to the last saved state, if one exists. Otherwise, clear the editor.
  useEffect(() => {
    const editor = editorRef.current;

    if (editor) {
      editor.setEditable(editModeActive);

      if (!editModeActive) {
        // EditorState must not be empty.
        if (editorState && editorState.root && editorState.root.children.length > 0) {
          const serializedState = editor.parseEditorState(editorState);
          editor.setEditorState(serializedState);
        } else {
          editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
        }
      }
    }
  }, [editModeActive, editorState]);

  return (
    <LexicalComposer initialConfig={editorConfig} data-testid={`${testidPrefix}_editor`}>
      <EditorProvider>
        <div className="editor-container">
          <ToolbarPlugin isToolbarActive={editModeActive} />
          <div className="editor-inner">
            <RichTextPlugin
              contentEditable={
                <ContentEditable
                  className="editor-input"
                  data-testid={`${testidPrefix}_contentEditable`}
                  aria-placeholder={placeholderText}
                  placeholder={(isEditable: boolean) => {
                    if (isEditable) {
                      return null;
                    }
                    return <div className="editor-placeholder">{placeholderText}</div>;
                  }}
                />
              }
              ErrorBoundary={LexicalErrorBoundary}
            />
            <OnChangePlugin onChange={onChange} ignoreSelectionChange />
            <HistoryPlugin />
            <LinkPlugin />
            <AutoLinkPlugin matchers={MATCHERS} />
            <ListPlugin />
            <EditorRefPlugin editorRef={editorRef} />
            <ClearEditorPlugin />
            <AutoFocusPlugin />
          </div>
        </div>
      </EditorProvider>
    </LexicalComposer>
  );
};

export default RichTextEditor;
