import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { LexicalEditor } from 'lexical';
import React, { createContext, FC } from 'react';

interface IEditorContext {
  editor: LexicalEditor;
}

const initialState: IEditorContext = {
  editor: {} as LexicalEditor,
};

const EditorContext = createContext<IEditorContext>({ ...initialState });

const EditorProvider: FC<any> = ({ children }) => {
  const [editor] = useLexicalComposerContext();

  return <EditorContext.Provider value={{ editor }}>{children}</EditorContext.Provider>;
};

const useEditor = (): IEditorContext => React.useContext(EditorContext);

export { EditorProvider, useEditor };
