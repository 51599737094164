import React, { useCallback, useContext } from 'react';
import { Select } from 'antd';
import ToolbarContext from '../ToolbarContext';
import { DEFAULT_FONT_SIZES } from './DefaultValues';

const fontDropdownOptions = DEFAULT_FONT_SIZES.map((size) => ({ value: `${size}px`, label: `${size}` }));

const FontSizeDropdown = () => {
  const { isToolbarActive, fontSize, applyStyleText, setFontColorSelectOpen, setFontBackgroundSelectOpen } =
    useContext(ToolbarContext);

  const onFontSizeSelect = useCallback(
    (e) => {
      applyStyleText({ 'font-size': e });
    },
    [applyStyleText]
  );

  return (
    <Select
      data-testid="richTextEditor_toolbar_fontSizeSelect"
      className="toolbar-item font-size"
      value={fontSize}
      options={fontDropdownOptions}
      onChange={onFontSizeSelect}
      disabled={!isToolbarActive}
      onClick={() => {
        setFontColorSelectOpen(false);
        setFontBackgroundSelectOpen(false);
      }}
    />
  );
};

export default FontSizeDropdown;
