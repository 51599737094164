/**
 * Allows searching for a single project, and then gathers other relevant
 * information to populate the "Add Project" modal automatically.
 */

/* eslint-disable security/detect-non-literal-fs-filename */
import { Modal, AutoComplete } from 'antd';
import React, { useEffect, useState } from 'react';
import { IProject, IProjectKitItem, useProjects } from 'context/projects';
import { IProjectLaboratory, useProjectLaboratories } from 'context/projectLaboratories';
import { Laboratory, useLaboratories } from 'context/laboratories';
import { ProjectAnalyteLink, useProjectAnalyteLinks } from 'context/projectAnalyteLinks';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ProjectIdPoolLink, useProjectIdPoolLinks } from 'context/projectIdPoolLinks';
import { useProjectKitItems } from 'context/projectKitItems';
import { useOrderTemplates } from 'context/orderTemplates';
import { OrderTemplate } from '@tassoinc/core-api-client';

interface Props {
  onCancel: () => void;
  onProjectSelect: (
    project: IProject,
    labId: string | null,
    labAdapterKey: string,
    analyteIds: string[],
    idPoolIds: string[],
    kitItemIds: string[],
    orderTemplateIds: string[]
  ) => void;
  open: boolean;
}

const ProjectSelector: React.FC<Props> = ({ onCancel, onProjectSelect, open }) => {
  const [isCollectingData, setIsCollectingData] = useState(false);
  const [selectedProject, setSelectedProject] = useState<IProject | null>(null);
  const [projectMatches, setProjectMatches] = useState<IProject[]>([]);
  const [options, setOptions] = useState<{ value: string; label: string }[]>([]);
  const { getProject, loadProjects } = useProjects();
  const { getProjectLaboratories } = useProjectLaboratories();
  const { getLaboratory } = useLaboratories();
  const { getProjectAnalyteLinks } = useProjectAnalyteLinks();
  const { getProjectIdPoolLinks } = useProjectIdPoolLinks();
  const { getProjectKitItems } = useProjectKitItems();
  const { getOrderTemplatesForProject } = useOrderTemplates();

  const onSearch = async (searchText: string): Promise<void> => {
    loadProjects(
      {
        nameLike: searchText,
        page: 1,
        pageLength: 20,
        includeTotalCount: false,
        sortBy: 'name',
        sortOrder: 'asc',
        skipStateUpdate: true,
      },
      async (projects) => {
        setProjectMatches(projects!);
        setOptions(projects!.map((p) => ({ value: p.id, label: p.name })));
      }
    );
  };

  const onSelect = async (projectId: string): Promise<void> => {
    const project = projectMatches.find((p) => p.id === projectId)!;

    setSelectedProject(project);
  };

  /**
   * Gather everything that's needed to populate the Add Project form.
   * Not every piece of information is present on the project record
   * itself. Some information needs to be looked up separately.
   */
  const onSubmit = async (): Promise<void> => {
    setIsCollectingData(true);

    const project = selectedProject!;

    const labLinks = await new Promise<IProjectLaboratory[]>((resolve) => {
      getProjectLaboratories(project.id, { skipStateUpdate: true }, async (foundLabLinks) => {
        resolve(foundLabLinks ?? []);
      });
    });

    let labAdapterKey = '';
    if (labLinks.length > 0) {
      const lab = await new Promise<Laboratory | null>((resolve) => {
        getLaboratory(labLinks[0].laboratoryId, { skipStateUpdate: true }, async (foundLab) => {
          resolve(foundLab);
        });
      });

      if (lab) {
        labAdapterKey = lab.adapterKey ?? '';
      }
    }

    const analyteLinks = await new Promise<ProjectAnalyteLink[]>((resolve) => {
      getProjectAnalyteLinks(project.id, {
        skipStateUpdate: true,
        callback: async (foundAnalyteLinks: ProjectAnalyteLink[] | null) => {
          resolve(foundAnalyteLinks ?? []);
        },
      });
    });

    const analyteIds = analyteLinks.map((a) => a.analyteId);

    const idPoolLinks = await new Promise<ProjectIdPoolLink[]>((resolve) => {
      getProjectIdPoolLinks(
        {
          projectIds: [project.id],
        },
        { hydrate: [] },
        async (foundIdPoolLinks: ProjectIdPoolLink[] | null) => {
          resolve(foundIdPoolLinks ?? []);
        }
      );
    });

    const idPoolIds = idPoolLinks.map((a) => a.idPoolId);

    const kitItemLinks = await new Promise<IProjectKitItem[]>((resolve) => {
      getProjectKitItems(project.id, async (foundKitItemLinks: IProjectKitItem[] | null) => {
        resolve(foundKitItemLinks ?? []);
      });
    });

    const kitItemIds = kitItemLinks.map((a) => a.kitItemId);

    const orderTemplates = await new Promise<OrderTemplate[]>((resolve) => {
      getOrderTemplatesForProject(project.id, undefined, async (templates) => {
        resolve(templates ?? []);
      });
    });

    const orderTemplateIds = orderTemplates.map((a) => a.id);

    onProjectSelect(
      { ...project, name: `${project.name} (copy)` },
      labLinks && labLinks[0].laboratoryId,
      labAdapterKey,
      analyteIds,
      idPoolIds,
      kitItemIds,
      orderTemplateIds
    );

    setIsCollectingData(false);
  };

  const openCustomerLetter = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();

    getProject(selectedProject!.id, {
      hydration: ['customerLetterUrl'],
      callback: async (loadedProject) => {
        window.open(loadedProject!.customerLetterUrl!, '_blank');
      },
    });
  };

  useEffect(() => {
    setSelectedProject(null);
  }, [open]);

  return (
    <Modal
      title="Populate settings from project..."
      visible={open}
      zIndex={1001}
      onCancel={onCancel}
      onOk={onSubmit}
      okButtonProps={{ loading: isCollectingData, disabled: !selectedProject }}
      confirmLoading={isCollectingData}
      okText="Copy settings"
      destroyOnClose
    >
      <p>
        <InfoCircleOutlined /> Only settings shown in the <strong>Add Project</strong> modal will be copied.
      </p>
      <AutoComplete
        style={{ width: '100%' }}
        options={options}
        onSearch={onSearch}
        onSelect={onSelect}
        onClear={() => setSelectedProject(null)}
        size="large"
        placeholder="Start typing to search projects"
        value={null || selectedProject?.name}
        disabled={isCollectingData}
        autoFocus
        allowClear
      />

      {selectedProject?.customerLetterKey && (
        <p>
          <br />
          <InfoCircleOutlined /> Selected project has a {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="#" type="button" role="button" onClick={openCustomerLetter}>
            customer letter
          </a>{' '}
          that must be added manually. It will not be copied automatically.
        </p>
      )}
    </Modal>
  );
};

export default ProjectSelector;
