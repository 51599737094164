import React, { useRef } from 'react';

import { InputRef, Space, Button, Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { ColumnType } from 'antd/lib/table';

import { SearchOutlined } from 'components/icons';

type SearchCallback = (searchKey: string, term: string | null) => Promise<any>;

const useBackendColumnFilter = <T extends unknown>() => {
  const searchInputRef = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: React.Key[],
    closeSearchbox: () => void,
    searchCallback: SearchCallback,
    searchKey: string
  ): void => {
    searchCallback(searchKey, selectedKeys.length > 0 ? selectedKeys.join(',') : null);
    closeSearchbox();
  };

  const handleReset = (closeSearchBox: () => void, searchCallback: SearchCallback, searchKey: string): void => {
    searchCallback(searchKey, null);
    closeSearchBox();
  };

  const handleChange = (e: CheckboxChangeEvent, keys: React.Key[], setKeys: (k: React.Key[]) => void): void => {
    const isChecked = e.target.checked;
    const value = e.target.value!;

    if (isChecked) {
      setKeys([...keys, value]);
    } else {
      setKeys(keys.filter((k) => k !== value));
    }
  };

  const getColumnSearchProps = (
    searchConfig: { by: string; term: string } | null,
    searchKey: string,
    values: { label: string; value: string }[],
    searchCallback: SearchCallback
  ): ColumnType<T> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters }) => (
      <div style={{ padding: 8 }}>
        {values.map((v) => (
          <div key={v.value}>
            <Checkbox
              checked={selectedKeys.includes(v.value)}
              value={v.value}
              onChange={(e) => handleChange(e, selectedKeys, setSelectedKeys)}
              data-testid={`column-filter_checkbox-${v.value}`}
            />{' '}
            {v.label}
          </div>
        ))}

        <Space style={{ marginTop: 8 }}>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, clearFilters!, searchCallback, searchKey)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            data-testid="column-filter_submit-button"
          >
            Search
          </Button>

          <Button
            onClick={() => handleReset(clearFilters!, searchCallback, searchKey)}
            size="small"
            style={{ width: 90 }}
            data-testid="column-filter_reset-button"
          >
            Reset
          </Button>
        </Space>
      </div>
    ),

    filtered: searchConfig !== null && searchConfig.by === searchKey,

    filteredValue: searchConfig !== null && searchConfig.by === searchKey ? searchConfig.term.split(',') : undefined,

    filterIcon: (filtered) => <SearchOutlined style={filtered ? { color: '#1890ff', fontSize: '18px' } : undefined} />,

    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          if (searchInputRef && searchInputRef.current) {
            searchInputRef.current.select();
          }
        });
      }
    },
  });

  return { getColumnSearchProps };
};

export default useBackendColumnFilter;
