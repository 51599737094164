import { Button, Form } from 'antd';
import { LinkOutlined } from 'components/icons';
import React, { useCallback, useContext, useState } from 'react';

import { $getSelection, $isNodeSelection, $isRangeSelection, RangeSelection, TextNode } from 'lexical';
import { $isLinkNode, LinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { useEditor } from '../../EditorContext';
import ToolbarContext from '../ToolbarContext';
import InsertLinkModal from './InsertLinkModal';
import { getSelectedNode } from '../../utils';

function selectionContainsLinkNodes(selection: RangeSelection): LinkNode[] | undefined {
  const selectedNodes = selection.getNodes();
  const linkNodes: LinkNode[] = [];
  for (const node of selectedNodes) {
    const parentNode = node.getParent();
    if ($isLinkNode(node)) {
      // From what I've seen, with selections involving LinkNodes, the TextNode is always what's
      // in the selection. This is just a CYA check in case of scenarios I haven't seen yet.
      linkNodes.push(node);
    } else if ($isLinkNode(parentNode)) {
      linkNodes.push(parentNode);
    }
  }

  return linkNodes.length > 0 ? linkNodes : undefined;
}

function getTextOfSelectionContainingLinkNode(selection: RangeSelection, linkNodeInSelection: LinkNode): string {
  const selectedNodes = selection.getNodes();
  const linkNodeText = linkNodeInSelection.getTextContent();
  const selectedText = selection.getTextContent();

  if (selectedNodes!.length === 1) {
    // If only one node is selected and it is a link node, take the full text of the node.
    // It's worth noting that this button will NOT be enabled if more than 1 LinkNode is in the selection.
    return linkNodeText;
  }
  // Now we have to figure out the correct selected text, based on if the LinkNode is the beginning, end,
  // or middle of the selection. The point is to make sure that we always have the full text of the LinkNode,
  // along with any adjacent text in the selection.
  const indexOfLinkNode = selectedNodes.indexOf(linkNodeInSelection);
  const points = selection.getStartEndPoints();
  if (indexOfLinkNode === 0) {
    const startOffset = points![0].offset;
    const offsetLength = linkNodeText.length - startOffset; // Number of characters from linkNodeText selected
    const nonLinkSelectedText = selectedText.slice(offsetLength);
    return linkNodeText.concat(nonLinkSelectedText);
  }
  if (indexOfLinkNode === selectedNodes.length - 1) {
    const endOffset = points![1].offset; // Number of characters from linkNodeText selected
    const nonLinkSelectedText = selectedText.slice(0, selectedText.length - endOffset);
    return nonLinkSelectedText.concat(linkNodeText);
  }
  // The link node is in the middle.
  return selectedText;
}

const InsertLinkButton = () => {
  const { editor } = useEditor();
  const { isToolbarActive, embeddedLink } = useContext(ToolbarContext);
  const [initialDisplayText, setInitialDisplayText] = useState<string | null>(null);
  const [isPopoverVisible, setPopoverVisible] = useState(false);

  const [form] = Form.useForm();

  const handleClickLinkButton = useCallback(() => {
    form.setFieldsValue({ url: embeddedLink });
    const currentState = editor.getEditorState();
    currentState.read(() => {
      const selection = $getSelection();
      let displayText = null;
      if ($isRangeSelection(selection)) {
        const linkNodesInSelection = selectionContainsLinkNodes(selection);
        // A selection on a LinkNode is always on the TextNode under the Link. This could mean a just the LinkNode is
        // selected or it could mean the text of the LinkNode is a subset of the selected text.
        if (linkNodesInSelection) {
          displayText = getTextOfSelectionContainingLinkNode(selection, linkNodesInSelection[0]);
        } else {
          displayText = selection.getTextContent();
        }
      } else if ($isNodeSelection(selection)) {
        displayText = selection.getTextContent();
      }

      form.setFieldsValue({ displayText });
      setInitialDisplayText(displayText);
    });

    setPopoverVisible(true);
  }, [editor, embeddedLink, form]);

  const handleOk = () => {
    // Explicitly use `null` here instead of an empty string or undefined as this tells lexical
    // to disable a link node.
    const linkValue = form.getFieldValue('url')?.length > 0 ? form.getFieldValue('url') : null;
    // `target: '_blank'` specifies that the link should be opened in a new tab.
    editor.dispatchCommand(TOGGLE_LINK_COMMAND, { url: linkValue, target: '_blank' });

    // Update the editor if the user has modified the text. This happens after the dispatch command
    // because we need to be able to treat the node as a link node.
    const displayText = form.getFieldValue('displayText');
    if (displayText && displayText !== initialDisplayText) {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          const selectedNode = getSelectedNode(selection);
          const parent = selectedNode.getParent();
          if ($isLinkNode(parent)) {
            const updatedText = new TextNode(displayText);
            selectedNode.replace(updatedText);
          } else {
            selection.removeText();
            selection.insertText(displayText);
          }
        } else if ($isNodeSelection(selection)) {
          console.log('Not Supported yet...');
        }
      });
    }
    setInitialDisplayText(null);

    setPopoverVisible(false);
    form.resetFields();
  };

  const handleCancel = () => {
    setPopoverVisible(false);
    setInitialDisplayText(null);
    form.resetFields();
  };

  return (
    <div>
      <Button
        data-testid="richTextEditor_toolbar_insertLinkButton"
        onClick={handleClickLinkButton}
        className={`toolbar-item spaced ${embeddedLink ? 'active' : ''}`}
        aria-label="Insert Link"
        disabled={!isToolbarActive}
      >
        <LinkOutlined />
      </Button>
      <InsertLinkModal form={form} open={isPopoverVisible} onOk={handleOk} onCancel={handleCancel} />
    </div>
  );
};

export default InsertLinkButton;
