import { createContext } from 'react';

interface IToolbarContext {
  isToolbarActive: boolean;
  embeddedLink: string | null;
  fontFamily: string;
  fontSize: string;
  fontColorHex: string;
  fontColorSelectOpen: boolean;
  setFontColorSelectOpen: (open: boolean) => void;
  bgColorHex: string;
  fontBackgroundSelectOpen: boolean;
  setFontBackgroundSelectOpen: (open: boolean) => void;
  applyStyleText: (styles: Record<string, string>) => void;
}

const initialState: IToolbarContext = {
  isToolbarActive: false,
  embeddedLink: null,
  fontFamily: '',
  fontSize: '',
  fontColorSelectOpen: false,
  setFontColorSelectOpen: () => {},
  fontBackgroundSelectOpen: false,
  setFontBackgroundSelectOpen: () => {},
  fontColorHex: '',
  bgColorHex: '',
  applyStyleText: () => {},
};

const ToolbarContext = createContext<IToolbarContext>(initialState);

export default ToolbarContext;
