import React, { FC, useState } from 'react';

import { Modal, Row, Col, Space, Input, Typography, Select, Button } from 'antd';
import { RcFile } from 'antd/lib/upload/interface';

import {
  DISTRIBUTION_MODELS,
  PACKAGE1_TYPES,
  PACKAGE2_TYPES,
  PROJECT_PHYSICIAN_APPROVAL_MODES,
  PROJECT_PURPOSES,
  Package1Type,
  Package2Type,
  PROJECT_TYPE_LABELS,
  DISTRIBUTION_MODEL_SOURCES,
} from 'constants/index';
import { Laboratory } from 'context/laboratories';

import { LabelIdentifierSource, ProjectType } from 'utils/types';
import { IProject } from 'context/projects';
import AnalyteSelectField from './AnalyteSelectField';
import ContactSelectField from './ContactSelectField';
import CustomerLetterUploadField from './CustomerLetterUploadField';
import CustomerSelectField from './CustomerSelectField';
import DeviceSelectField from './DeviceSelectField';
import DistributionModelSelectField from './DistributionModelSelectField';
import LabelingStrategySelectField from './LabelingStrategySelectField';
import LaboratorySelectField from './LaboratorySelectField';
import ShippingSpeedSelectField from './ShippingSpeedSelectField';
import LabelIdentifierSourceField from './LabelIdentifierSourceField';
import BillingMode from './BillingMode';
import PatientExperienceField from './PatientExperienceField';
import ProjectSelector from './ProjectSelector';

interface ICreateProjectModal {
  visible: boolean;
  onOk: (laboratory: any) => void;
  onCancel: () => void;
  loading: boolean;
}

const { Text } = Typography;

type State = {
  customerId: string | null;
  contactId: string | null;
  deviceType: string | null;
  projectType: ProjectType | null;
  laboratoryId: string | null;
  analytes: string[];
  labelingStrategy: string | null;
  customerLetterFile: RcFile | null;
  package1Type: Package1Type;
  package2Type: Package2Type;
  distributionModel: keyof typeof DISTRIBUTION_MODELS;
  labAdapterKey: string;
  labelIdentifierSource: LabelIdentifierSource | null;
  upsAccountIdToPatient: string | null;
  upsAccountIdToLab: string | null;
  patientExperienceEnabled: boolean;
  analyteIds: string[];
  idPoolIds: string[];
  kitItemIds: string[];
  orderTemplateIds: string[];
};

const CreateProjectModal: FC<ICreateProjectModal> = ({ visible, onOk, onCancel, loading }) => {
  const [showProjectSelector, setShowProjectSelector] = useState(false);
  const [projectName, setProjectName] = useState('');
  const [projectCode, setProjectCode] = useState('');
  const [projectPurpose, setProjectPurpose] = useState('');
  const [physicianApprovalMode, setPhysicianApprovalMode] = useState('customerProvided');
  const [initialSalesOrder, setInitialSalesOrder] = useState<string>('');
  const [replacementSalesOrder, setReplacementSalesOrder] = useState<string>('');
  const [projectCustomerReferenceNumber, setProjectCustomerReferenceNumber] = useState('');
  const [packingInstructions, setPackingInstructions] = useState<string>('');
  const [state, setState] = useState<State>({
    customerId: null,
    contactId: null,
    deviceType: null,
    projectType: null,
    laboratoryId: null,
    analytes: [],
    labelingStrategy: null,
    customerLetterFile: null,
    package1Type: PACKAGE1_TYPES.none,
    package2Type: PACKAGE2_TYPES.none,
    distributionModel: 'directToPatient',
    labAdapterKey: '',
    labelIdentifierSource: null,
    upsAccountIdToPatient: null,
    upsAccountIdToLab: null,
    patientExperienceEnabled: false,
    analyteIds: [],
    idPoolIds: [],
    kitItemIds: [],
    orderTemplateIds: [],
  });

  const isSubmitBtnDisabled = () =>
    !projectName ||
    !projectCode ||
    projectCode.length !== 5 ||
    !state.customerId ||
    !state.deviceType ||
    !state.laboratoryId ||
    !state.distributionModel ||
    !state.projectType ||
    !state.labelIdentifierSource;

  const handleSubmit = () => {
    onOk({
      name: projectName,
      code: projectCode,
      projectType: state.projectType || undefined,
      projectPurpose: projectPurpose || undefined,
      physicianApprovalMode: projectPurpose === 'diagnosticTesting' ? physicianApprovalMode || undefined : undefined,
      customerId: state.customerId!,
      contactId: state.contactId,
      deviceType: state.deviceType!,
      package1Type: state.package1Type,
      package2Type: state.package2Type,
      initialSalesOrder: initialSalesOrder || undefined,
      replacementSalesOrder: replacementSalesOrder || undefined,
      packingInstructions: packingInstructions || undefined,
      laboratoryId: state.laboratoryId,
      labelingStrategy: state.labelingStrategy || undefined,
      customerLetterFile: state.customerLetterFile || undefined,
      distributionModel: state.distributionModel,
      analytes: state.analytes,
      labelIdentifierSource: state.labelIdentifierSource,
      upsAccountIdToPatient: state.upsAccountIdToPatient || undefined,
      upsAccountIdToLab: state.upsAccountIdToLab || undefined,
      customerReferenceNumber: projectCustomerReferenceNumber || undefined,
      patientExperienceEnabled: state.patientExperienceEnabled || undefined,
      analyteIds: state.analytes,
      idPoolIds: state.idPoolIds,
      kitItemIds: state.kitItemIds,
      orderTemplateIds: state.orderTemplateIds,
    });
  };

  const copySettingFromProject = (
    sourceProject: IProject,
    laboratoryId: string | null,
    labAdapterKey: string,
    analyteIds: string[],
    idPoolIds: string[],
    kitItemIds: string[],
    orderTemplateIds: string[]
  ): void => {
    setProjectName(sourceProject.name);
    setProjectPurpose(sourceProject.purpose);
    setPhysicianApprovalMode(sourceProject.physicianApprovalMode);
    setInitialSalesOrder(sourceProject.initialSalesOrder);
    setReplacementSalesOrder(sourceProject.replacementSalesOrder);
    setProjectCustomerReferenceNumber(sourceProject.customerReferenceNumber || '');
    setPackingInstructions(sourceProject.packingInstructions || '');
    setState({
      customerId: sourceProject.customerId,
      contactId: null,
      deviceType: sourceProject.deviceType,
      projectType: sourceProject.projectType as ProjectType | null,
      laboratoryId,
      analytes: analyteIds,
      labelingStrategy: sourceProject.labelingStrategy,
      customerLetterFile: null,
      package1Type: sourceProject.package1Type,
      package2Type: sourceProject.package2Type,
      distributionModel: sourceProject.distributionModel,
      labAdapterKey,
      labelIdentifierSource: sourceProject.labelIdentifierSource as LabelIdentifierSource | null,
      upsAccountIdToPatient: sourceProject.upsAccountIdToPatient,
      upsAccountIdToLab: sourceProject.upsAccountIdToLab,
      patientExperienceEnabled: sourceProject.patientExperienceEnabled,
      analyteIds,
      idPoolIds,
      kitItemIds,
      orderTemplateIds,
    });

    setShowProjectSelector(false);
  };

  return (
    <>
      <ProjectSelector
        onProjectSelect={copySettingFromProject}
        open={showProjectSelector}
        onCancel={() => setShowProjectSelector(false)}
      />
      <Modal
        wrapClassName="Modal CreateProjectModal"
        visible={visible}
        title={
          <>
            Add Project{' '}
            <Button
              type="text"
              size="small"
              onClick={() => setShowProjectSelector(true)}
              className="CopyProjectLink"
              data-testId="project-copy-existing-project"
            >
              Copy Existing Project
            </Button>
          </>
        }
        okText="Add"
        cancelText="Cancel"
        onCancel={onCancel}
        onOk={handleSubmit}
        confirmLoading={loading}
        centered
        width="640px"
        forceRender
        okButtonProps={{ disabled: isSubmitBtnDisabled() || loading }}
      >
        <Space direction="vertical" size="middle">
          <Row gutter={24}>
            <Col span={12}>
              <Space direction="vertical" size="middle">
                <Space direction="vertical">
                  <Text strong>Name</Text>

                  <Input
                    value={projectName}
                    onChange={(e: any) => setProjectName(e.target.value)}
                    placeholder="Enter project name"
                    data-testId="project-name"
                  />
                </Space>

                <Space direction="vertical">
                  <Text strong>Code</Text>

                  <Input
                    value={projectCode}
                    onChange={(e: any) => setProjectCode(e.target.value)}
                    placeholder="Enter code (5 symbols)"
                    required
                    minLength={5}
                    maxLength={5}
                    pattern="[a-zA-Z0-9]{5}"
                    data-testId="project-code"
                  />
                </Space>

                <Space direction="vertical">
                  <Text strong>Type</Text>

                  <Select
                    value={state.projectType ?? undefined}
                    onSelect={(projectType: ProjectType) => setState((prevState) => ({ ...prevState, projectType }))}
                    placeholder="Select project type"
                    data-testId="project-type"
                  >
                    {Object.keys(PROJECT_TYPE_LABELS).map((t) => (
                      <Select.Option key={t} value={t}>
                        {PROJECT_TYPE_LABELS[t as ProjectType]}
                      </Select.Option>
                    ))}
                  </Select>
                </Space>

                <Space direction="vertical">
                  <Text strong>Purpose</Text>

                  <Select
                    value={projectPurpose ?? undefined}
                    onSelect={(value: string) => setProjectPurpose(value as string)}
                    placeholder="Select project purpose"
                    data-testId="project-purpose"
                  >
                    {Object.keys(PROJECT_PURPOSES).map((t) => (
                      <Select.Option key={t} value={t}>
                        {PROJECT_PURPOSES[t as keyof typeof PROJECT_PURPOSES]}
                      </Select.Option>
                    ))}
                  </Select>
                </Space>

                <PatientExperienceField
                  value={state.patientExperienceEnabled}
                  onChange={(enabled: any) =>
                    setState((prevState) => ({
                      ...prevState,
                      patientExperienceEnabled: enabled === 'true',
                    }))
                  }
                />

                {projectPurpose === 'diagnosticTesting' && (
                  <Space direction="vertical">
                    <Text strong>Physician Approval Mode</Text>

                    <Select
                      value={physicianApprovalMode ?? undefined}
                      onSelect={(value: string) => setPhysicianApprovalMode(value as string)}
                      placeholder="Select physician approval mode"
                      data-testId="project-physician-approval-mode"
                    >
                      {Object.keys(PROJECT_PHYSICIAN_APPROVAL_MODES).map((t) => (
                        <Select.Option key={t} value={t}>
                          {PROJECT_PHYSICIAN_APPROVAL_MODES[t as keyof typeof PROJECT_PHYSICIAN_APPROVAL_MODES]}
                        </Select.Option>
                      ))}
                    </Select>
                  </Space>
                )}

                <Space direction="vertical">
                  <Text strong>Initial Sales Order</Text>

                  <Input
                    value={initialSalesOrder}
                    onChange={(e: any) => setInitialSalesOrder(e.target.value === '' ? '' : e.target.value)}
                    placeholder="Enter initial sales order"
                    data-testId="project-initial-sales-order"
                  />
                </Space>

                <Space direction="vertical">
                  <Text strong>Replacement Sales Order</Text>

                  <Input
                    value={replacementSalesOrder}
                    onChange={(e: any) => setReplacementSalesOrder(e.target.value === '' ? '' : e.target.value)}
                    placeholder="Enter replacement sales order"
                    data-testId="project-replacement-sales-order"
                  />
                </Space>

                <CustomerSelectField
                  value={state.customerId}
                  onChange={(customerId: any) => setState((prevState) => ({ ...prevState, customerId }))}
                />

                <ContactSelectField
                  customerId={state.customerId}
                  value={state.contactId}
                  onChange={(contactId: any) => setState((prevState) => ({ ...prevState, contactId }))}
                />

                <Space direction="vertical">
                  <Text strong>Customer Letter</Text>

                  <CustomerLetterUploadField
                    onChange={(file) => {
                      setState((s) => ({ ...s, customerLetterFile: file as any }));
                    }}
                  />
                </Space>
              </Space>
            </Col>

            <Col span={12}>
              <Space direction="vertical" size="middle">
                <DeviceSelectField
                  value={state.deviceType}
                  onChange={(deviceType: any) => setState((prevState) => ({ ...prevState, deviceType }))}
                />

                <DistributionModelSelectField
                  value={state.distributionModel}
                  onChange={(distributionModel) =>
                    setState((s) => ({
                      ...s,
                      distributionModel,
                      package1Type:
                        distributionModel === DISTRIBUTION_MODEL_SOURCES.atCustomerSite
                          ? PACKAGE1_TYPES.none
                          : s.package1Type,
                      package2Type:
                        distributionModel === DISTRIBUTION_MODEL_SOURCES.atCustomerSite
                          ? PACKAGE2_TYPES.priority
                          : s.package2Type,
                    }))
                  }
                />

                <LabelIdentifierSourceField
                  selectedValue={state.labelIdentifierSource}
                  projectType={state.projectType}
                  onChange={(labelIdentifierSource: any) =>
                    setState((prevState) => ({ ...prevState, labelIdentifierSource }))
                  }
                />

                <LabelingStrategySelectField
                  value={state.labelingStrategy}
                  onChange={(labelingStrategy: any) => setState((prevState) => ({ ...prevState, labelingStrategy }))}
                />

                <LaboratorySelectField
                  isDisabled={false}
                  selectionId={state.laboratoryId}
                  onChange={(lab: Laboratory) =>
                    setState((prevState) => ({
                      ...prevState,
                      laboratoryId: lab.id,
                      labAdapterKey: lab.adapterKey,
                    }))
                  }
                  selectOnly={false}
                />

                <AnalyteSelectField
                  values={state.analytes}
                  labAdapterKey={state.labAdapterKey}
                  onChange={(analyteIds: string[]) =>
                    setState((prevState) => ({
                      ...prevState,
                      analytes: analyteIds,
                    }))
                  }
                />

                <ShippingSpeedSelectField
                  packageType="toPatient"
                  distributionModel={state.distributionModel}
                  value={state.package1Type}
                  onChange={(value) => setState((s) => ({ ...s, package1Type: value }))}
                />

                <ShippingSpeedSelectField
                  packageType="toLab"
                  distributionModel={state.distributionModel}
                  value={state.package2Type}
                  onChange={(value) => setState((s) => ({ ...s, package2Type: value as Package2Type }))}
                />

                <BillingMode
                  upsAccountIdToPatient={state.upsAccountIdToPatient}
                  upsAccountIdToLab={state.upsAccountIdToLab}
                  package1Type={state.package1Type}
                  package2Type={state.package2Type}
                  onChange={(upsAccountIdToPatient, upsAccountIdToLab) =>
                    setState((s) => ({ ...s, upsAccountIdToPatient, upsAccountIdToLab }))
                  }
                />

                <Space direction="vertical">
                  <Text strong>Shipping Reference Number</Text>

                  <Input
                    value={projectCustomerReferenceNumber}
                    onChange={(e: any) => setProjectCustomerReferenceNumber(e.target.value)}
                    placeholder="Enter reference number"
                    maxLength={30}
                    data-testId="project-shipping-reference-number"
                  />
                </Space>

                <Space direction="vertical">
                  <Text strong>Packing Instructions</Text>

                  <Input.TextArea
                    rows={2}
                    value={packingInstructions}
                    onChange={(e: any) => setPackingInstructions(e.target.value === '' ? '' : e.target.value)}
                    placeholder="Enter packing instructions"
                    data-testId="project-packing-instructions"
                  />
                </Space>
              </Space>
            </Col>
          </Row>
        </Space>
      </Modal>
    </>
  );
};

export default CreateProjectModal;
