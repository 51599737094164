import publicApiHttpClient from 'services/publicApiHttpClient';

export const createCredential = (data: Record<string, any>): Promise<any> =>
  publicApiHttpClient.post('/credentials', data).then((response) => response.data);

export const getCredentialsByCustomerId = (customerId: string): Promise<any> =>
  publicApiHttpClient.get(`/credentials?customerId=${customerId}`).then((response) => response.data);

export const updateCredential = (data: Record<string, any>): Promise<any> =>
  publicApiHttpClient.patch('/credentials', data).then((response) => response.data);

export const disableCredential = (clientId: string, customerId: string): Promise<any> =>
  publicApiHttpClient
    .delete('/credentials', { data: { customerId, username: clientId } })
    .then((response) => response.data);
