import { OrderTemplate } from '@tassoinc/core-api-client';
import { IProject } from 'context/projects';
import { sortOrderTemplates } from 'utils/orderTemplates';
import { SummarizedOrderTemplate, FILTER_SHOW_ALL } from './Project/ProjectShipments';

/**
 * Keeps track of packing instruction that comes from Order Templates.
 * The key is the order template id. The value is the upload id that represents
 * the actual file with instructions
 */
export type OrderTemplatePackingInstructions = Record<string, string>;

export const computeSummarizedOrderTemplates = (
  orderTemplates: OrderTemplate[] | null,
  project: IProject,
  isAdmin: boolean,
  isCustomerService: boolean
): SummarizedOrderTemplate[] => {
  let result: SummarizedOrderTemplate[] = [];

  // Not loaded yet.
  if (orderTemplates === null) {
    return [];
  }
  for (const ot of orderTemplates) {
    // Strip version off so we get orders with all versions of this template
    // More info on how versions in Order Template IDs are encoded:
    // https://tassoinc.atlassian.net/wiki/spaces/CODE/pages/2026864642/Order+Configuration+template+Ids
    result.push({ id: ot.id.replace(/::v[0-9]+/, ''), name: ot.name, templateId: ot.templateId });
  }

  if (project.orderTemplatesViewOrder.length === 0) {
    result.sort((a, b) => a.name.localeCompare(b.name));
  } else {
    result = sortOrderTemplates(result, project.orderTemplatesViewOrder);
  }

  result.unshift({ id: 'none', name: 'None', templateId: '' });
  if (isAdmin || isCustomerService) {
    // Admin doesn't show numOrdersAwaitingShipment
    result.unshift({ id: FILTER_SHOW_ALL, name: 'All Configurations', templateId: '' });
  }
  return result;
};
