import React, { useCallback, useContext } from 'react';
import { Select } from 'antd';
import ToolbarContext from '../ToolbarContext';
import { DEFAULT_FONT_FAMILIES } from './DefaultValues';

const fontFamilyOptions = DEFAULT_FONT_FAMILIES.map((fontFam) => {
  const fontFamily = `${fontFam.font}, ${fontFam.fallbacks}`;
  return { value: fontFamily, label: <div style={{ fontFamily }}>{fontFam.font.replaceAll('"', '')}</div> };
});

const FontFamilyDropdown = () => {
  const { isToolbarActive, fontFamily, applyStyleText, setFontColorSelectOpen, setFontBackgroundSelectOpen } =
    useContext(ToolbarContext);

  const onFontFamilySelect = useCallback(
    (f) => {
      applyStyleText({ 'font-family': f });
    },
    [applyStyleText]
  );

  return (
    <Select
      data-testid="richTextEditor_toolbar_fontFamilySelect"
      className="toolbar-item font-family"
      dropdownMatchSelectWidth={160}
      value={fontFamily}
      options={fontFamilyOptions}
      onChange={onFontFamilySelect}
      disabled={!isToolbarActive}
      onClick={() => {
        setFontColorSelectOpen(false);
        setFontBackgroundSelectOpen(false);
      }}
    />
  );
};

export default FontFamilyDropdown;
