import {
  createCredential,
  disableCredential,
  getCredentialsByCustomerId,
  updateCredential,
} from 'api/public-api/credentials';
import React, { FC, useEffect, useState } from 'react';
import { Button, Dropdown, Menu, Table } from 'antd';
import 'antd/dist/antd.css';
import PublicApiCredentialFormModal from 'components/PublicApiCredentialFormModal';
import { CheckCircleOutlined, EditOutlined, EllipsisOutlined, PlusCircleOutlined } from 'components/icons';
import { IProject } from 'context/projects';
import PublicApiCredentialSecretModal from 'components/PublicApiCredentialSecretModal';
import Notification from 'utils/notification';

interface CognitoCredential {
  clientId: string;
  createdByUserId: string;
  createdDate: string;
  customerId: string;
  isActive: boolean;
  projectIds: string[];
  projectNames: string[];
}

interface Props {
  customerId: string;
  projects: IProject[];
  isAdmin: boolean;
}

const PublicApiCredentialManagerContainer: FC<Props> = ({ customerId, projects, isAdmin }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [credentialData, setCredentialData] = useState<Record<string, any> | null>(null);
  const [credentials, setCredentials] = useState<CognitoCredential[]>([]);
  const [editCredential, setEditCredential] = useState<CognitoCredential | null>(null);
  const [openFormModal, setOpenFormModal] = useState<boolean>(false);
  const [openSecretModal, setOpenSecretModal] = useState<boolean>(false);

  const fetchCredentials = async () => {
    try {
      const response = await getCredentialsByCustomerId(customerId);
      const formattedCredentials = response.map((credential: CognitoCredential) => ({
        ...credential,
        createdDate: credential.createdDate.split('T')[0],
        createdByUserId: credential.createdByUserId.split('@')[0],
        projectNames: credential.projectIds.map((id: string) => projects.find((p: IProject) => p.id === id)?.name),
      }));
      setCredentials(formattedCredentials);
    } catch (error) {
      console.error('Error fetching credentials', error);
    }
  };

  useEffect(() => {
    fetchCredentials();
  }, [customerId, projects]);

  useEffect(() => {
    if (credentialData) {
      setOpenSecretModal(true);
    }
  }, [credentialData]);

  const handleCreateCredential = async (projectIds: string[]) => {
    setIsLoading(true);
    let result: CognitoCredential;
    try {
      if (editCredential) {
        result = await updateCredential({ customerId, projectIds, clientId: editCredential.clientId });
      } else {
        result = await createCredential({ customerId, projectIds });
        setCredentialData(result);
      }
      await fetchCredentials(); // Update the list to reflect changes.
      setOpenFormModal(false);
    } catch (error) {
      console.error(`Error ${editCredential ? 'updating' : 'creating'} credential`, error);
      Notification({ type: 'error', message: error instanceof Error ? error.message : JSON.stringify(error) });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDisableCredential = async (credential: CognitoCredential) => {
    if (credential.isActive) {
      setIsLoading(true);
      try {
        await disableCredential(credential.clientId, customerId);
        await fetchCredentials();
      } catch (error) {
        console.error('Error disabling credential', error);
        Notification({ type: 'error', message: error instanceof Error ? error.message : JSON.stringify(error) });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onEditCredential = (credential: CognitoCredential) => {
    setEditCredential(credential);
    setOpenFormModal(true);
  };

  const onCancelModal = () => {
    setOpenFormModal(false);
    setEditCredential(null);
  };

  return (
    <>
      <PublicApiCredentialFormModal
        visible={openFormModal}
        projects={projects.filter((project) => project.customerId === customerId)}
        loading={isLoading}
        existingSelectedProjectIds={editCredential?.projectIds}
        onOk={handleCreateCredential}
        onCancel={onCancelModal}
      />
      <PublicApiCredentialSecretModal
        visible={openSecretModal}
        secret={credentialData?.secret}
        clientId={credentialData?.clientId}
        onOk={() => setOpenSecretModal(false)}
      />
      <div className="PublicApiCredentialManager">
        <h3>
          Public API Credentials
          {isAdmin && (
            <Button type="link" onClick={() => setOpenFormModal(true)}>
              <PlusCircleOutlined />
            </Button>
          )}
        </h3>
        <Table
          columns={[
            {
              title: 'Client ID',
              dataIndex: 'clientId',
              key: 'clientId',
            },
            {
              title: 'Projects',
              dataIndex: 'projectNames',
              key: 'projectNames',
              render: (projectNames?: string[]) => projectNames?.map((name) => <div>{name}</div>),
            },
            {
              title: 'Created By',
              dataIndex: 'createdByUserId',
              key: 'createdByUserId',
            },
            {
              title: 'Created Date',
              dataIndex: 'createdDate',
              key: 'createdDate',
            },
            {
              title: 'Active?',
              dataIndex: 'isActive',
              key: 'isActive',
              render: (isActive: boolean) => (isActive ? 'Yes' : 'No'),
            },
            {
              title: 'Actions',
              key: 'actions',
              align: 'center',
              render: (_value: any, record: CognitoCredential) =>
                !record.isActive ? (
                  'N/A'
                ) : (
                  <Dropdown
                    trigger={['click']}
                    overlay={
                      <Menu
                        onClick={(e) => {
                          e.domEvent.stopPropagation();
                        }}
                      >
                        <Menu.Item key="1" icon={<EditOutlined />} onClick={() => onEditCredential(record)}>
                          Edit
                        </Menu.Item>
                        <Menu.Item
                          key="2"
                          icon={<CheckCircleOutlined />}
                          onClick={() => handleDisableCredential(record)}
                        >
                          Disable
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <Button
                      icon={<EllipsisOutlined />}
                      type="text"
                      size="middle"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                  </Dropdown>
                ),
            },
          ]}
          dataSource={credentials}
          rowKey="clientId"
          bordered
          size="small"
          pagination={{
            pageSize: 10,
          }}
        />
      </div>
    </>
  );
};

export default PublicApiCredentialManagerContainer;
