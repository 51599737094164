import React, { useCallback, useContext, useEffect } from 'react';
import { Popover, Button } from 'antd';
import Compact from '@uiw/react-color-compact';

import { useEditor } from 'components/RichTextEditor/EditorContext';

import { FontColorsOutlined } from '@ant-design/icons';
import ToolbarContext from '../ToolbarContext';
import { DEFAULT_FONT_COLOR } from './DefaultValues';

const FontColorPicker = () => {
  const { editor } = useEditor();
  const {
    isToolbarActive,
    fontColorHex,
    applyStyleText,
    fontColorSelectOpen,
    setFontColorSelectOpen,
    fontBackgroundSelectOpen,
    setFontBackgroundSelectOpen,
  } = useContext(ToolbarContext);

  const onFontColorSelect = useCallback(
    (value: string) => {
      applyStyleText({ color: value });
    },
    [applyStyleText]
  );

  useEffect(() => {
    if (!editor.isEditable()) {
      setFontColorSelectOpen(false);
    }
  }, [editor.isEditable()]);

  return (
    <Button
      data-testid="richTextEditor_toolbar_fontColorButton"
      className="toolbar-item spaced"
      aria-label="Font Color"
      disabled={!isToolbarActive}
      onClick={() => {
        if (editor.isEditable()) {
          if (fontBackgroundSelectOpen) {
            setFontBackgroundSelectOpen(false);
          }
          setFontColorSelectOpen(!fontColorSelectOpen);
        }
      }}
    >
      <Popover
        data-testid="richTextEditor_toolbar_fontColorPopover"
        placement="bottom"
        title="Font Color"
        trigger="click"
        open={fontColorSelectOpen}
        onOpenChange={() => {
          if (fontColorSelectOpen) {
            // Only toggle it if the Popover is already open, otherwise we get a race condition
            // with the outer Button.
            setFontColorSelectOpen(false);
          }
        }}
        content={
          <div>
            <Button
              data-testid="richTextEditor_toolbar_fontColorPopover_resetToDefaultButton"
              className="color-selector reset-button"
              aria-label="Clear Font Color"
              block
              onClick={() => {
                onFontColorSelect(DEFAULT_FONT_COLOR);
                setFontColorSelectOpen(false);
              }}
            >
              Automatic
            </Button>
            <Compact
              data-testid="richTextEditor_toolbar_fontColorPopover_colorChart"
              color={fontColorHex}
              style={{ background: `rgba(255, 255, 255, 0.85)` }}
              onChange={(color) => {
                onFontColorSelect(color.hex);
                setFontColorSelectOpen(false);
              }}
            />
          </div>
        }
      >
        <FontColorsOutlined />
      </Popover>
    </Button>
  );
};

export default FontColorPicker;
