import { Form, Input, Modal } from 'antd';
import React, { FC } from 'react';

interface InsertLinkModal {
  form: any;
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
}

const InsertLinkModal: FC<InsertLinkModal> = ({ form, open, onOk, onCancel }) => (
  <Modal
    data-testid="richTextEditor_toolbar_insertLinkModal"
    open={open}
    title="Link"
    okText="Ok"
    cancelText="Cancel"
    onOk={onOk}
    onCancel={onCancel}
  >
    <Form
      data-testid="richTextEditor_toolbar_insertLinkModal_form"
      className="Form InsertLinkModal__Form"
      form={form}
      layout="vertical"
      name="InsertLinkModalForm"
    >
      <Form.Item name="url" label="Address:">
        <Input data-testid="richTextEditor_toolbar_insertLinkModal_form_urlInput" />
      </Form.Item>
      <Form.Item name="displayText" label="Text to display:">
        <Input data-testid="richTextEditor_toolbar_insertLinkModal_form_displayTextInput" />
      </Form.Item>
    </Form>
  </Modal>
);

export default InsertLinkModal;
