import { Button } from 'antd';
import { useEditor } from 'components/RichTextEditor/EditorContext';
import React, { FC, ReactNode, useContext } from 'react';
import { LexicalCommand } from 'lexical';
import ToolbarContext from '../ToolbarContext';

interface ISimpleDispatchCommandButton {
  lexicalCommand: LexicalCommand<any>;
  commandPayload?: string;
  ariaLabel: string;
  activeOnSelect: boolean;
  disabledOverride?: boolean;
  children?: ReactNode;
}

const SimpleDispatchCommandButton: FC<ISimpleDispatchCommandButton> = ({
  lexicalCommand,
  commandPayload,
  ariaLabel,
  activeOnSelect,
  disabledOverride,
  children,
}) => {
  const { editor } = useEditor();
  const { isToolbarActive } = useContext(ToolbarContext);

  const dataTestIdSpecifier = ariaLabel.replaceAll(' ', '');

  const buttonDisabled = disabledOverride !== undefined ? disabledOverride || !isToolbarActive : !isToolbarActive;

  return (
    <Button
      data-testid={`richTextEditor_toolbar_align${dataTestIdSpecifier}Button`}
      onClick={() => {
        editor.dispatchCommand(lexicalCommand, commandPayload?.toLowerCase());
      }}
      className={`toolbar-item spaced ${activeOnSelect ? 'active' : ''}`}
      aria-label={ariaLabel}
      disabled={buttonDisabled}
    >
      {children}
    </Button>
  );
};

export default SimpleDispatchCommandButton;
